import { Component, OnInit } from '@angular/core';
import  { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserData } from '../../_models/UserData';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})



export class LoginComponent implements OnInit {
  showPass: boolean = false;
  userModel = new UserData('','');
  submitted:boolean = false;
  showVerify:boolean = false;

  showSuccessMessage:boolean = false;
  messageSubmitSuccess:string = '';

  showErrorMessage:boolean = false;
  messageSubmitError:string = '';

  alertColor:string = '';
  messageSubmit: string = '';
  showMessage: boolean = false;

  constructor( 
    private router:Router, 
    public authService: AuthService,
    public api: ApiService) { }

  ngOnInit(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const verification = urlParams.get('verification')
    if(verification==='1'){
      this.showVerify = true;
    }
    localStorage.removeItem('user');
    localStorage.removeItem('key');
  }

  closeSpan(){
    this.showVerify = false;
    this.showSuccessMessage = false;
    this.showErrorMessage = false;
  }

  onSubmit(f:NgForm){
    this.validateSubmit(f)
  }

  validateSubmit(loginForm:NgForm){
    this.submitted = true;
    this.showSuccessMessage = false;
    this.showErrorMessage = false;

    if(this.userModel.email!=='' && this.userModel.password!==''){
    
      if(loginForm.form.status ==='VALID'){
        const formData = new FormData();
        formData.append('email', this.userModel.email);
        formData.append('password', this.userModel.password);

        this.api.postLogin(formData).subscribe((data)=>{
         
          console.log('data',data);
       
          localStorage.setItem('user', JSON.stringify(this.userModel));
          localStorage.setItem('key', data.key);

          this.showMessage =true;
          this.alertColor ='success';
          this.messageSubmit= 'Success... Now Logging In..';

          this.getUserDetails(data.key);
          
        },(err)=>{
          console.log('validateSubmit err',err.status)
          this.messageSubmit = '';
          this.showMessage =true;
          this.alertColor ='danger';
          if(err.status === 400){
         
      
            for (const key in err.error) {
            
              this.messageSubmit += '\n\n'+"<small>"+`${err.error[key]} </small>`;
            }
          }else{
            this.messageSubmit += '\n\n'+"<small>There is an internal error on the server. Please try again after some time.</small>";
          }
        
         
        });
        
      }
     
    }
  }

  getUserDetails(key:any){
    this.api.getUserDetailsLogin(key).subscribe((data)=>{
      console.log('getUserDetailsLogin',data);
      localStorage.setItem('userDetails',JSON.stringify(data))
      this.api.currentUserSubject.next(data);
      let adminUser:any =false;
      // this.headData = data
      if(data.groups[0] ===1){
        this.router.navigate(['/admin/dashboard'])
        adminUser = true;
      }else{
        this.router.navigate(['/admin/user-dashboard']);
        adminUser = false;
      }
      localStorage.setItem('userAdmin',adminUser)
    })
  }



  togglePassword() {
    this.showPass = !this.showPass;
    const passwordInput: any = document.getElementById('passwordInput');
    const icon: any = document.querySelector('#togglePassword i');

    if (this.showPass) {
      passwordInput.type = 'text';
      icon.classList.remove('bi-eye-fill');
      icon.classList.add('bi-eye-slash');
    } else {
      passwordInput.type = 'password';
      icon.classList.remove('bi-eye-slash');
      icon.classList.add('bi-eye-fill');
    }

  }
}
