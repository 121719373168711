import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
  private currentUserSubject = new BehaviorSubject<any>(null);
  constructor(
    public api:ApiService,
    public router: Router,
    public authService:AuthService, 
    ){
    const user =  this.api.currentUserValue;

    console.log('user', user)
    if(!user){
      localStorage.clear();
      this.currentUserSubject.next(null);
      this.router.navigate(['/login']);
      let dataKey = environment.firebase.apiKeyStorage;
      this.authService.SignOut(dataKey);

    }
  }
  ngOnInit(): void {
    
  }
}
