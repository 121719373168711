import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import {  RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/shared/login/login.component';
import { AdminComponent } from './modules/admin/admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableModule, MatTableDataSource} from '@angular/material/table'; 
import { MatButtonModule,} from '@angular/material/button';
import { MatInputModule,} from '@angular/material/input'; 
import { MatPaginatorModule,} from '@angular/material/paginator'; 

import { MatSnackBarModule} from '@angular/material/snack-bar';
import { AuthGuard } from '../app/auth/auth.guard';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { TestComponent } from './modules/shared/test/test.component';
import { Select2Module } from 'ng-select2-component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ResetForgotPasswordComponent } from './modules/shared/reset-forgot-password/reset-forgot-password.component'; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'signin',
    pathMatch: 'full'
    // component: LoginComponent, 
    // loadChildren: () => import('./modules/shared/login/login.module').then(m => m.LoginModule)
  },
  { 
    path: 'signin', 
    component: LoginComponent, 
    loadChildren: () => import('./modules/shared/login/login.module').then(m => m.LoginModule)
  },
  { 
    path: 'forgot-password', 
    loadChildren: () => import('./modules/shared/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) 
  },
  { 
    path: 'signup', 
    loadChildren: () => import('./modules/shared/signup/signup.module').then(m => m.SignupModule)
  },
  { 
    path: 'change-password', 
    loadChildren: () => import('./modules/shared/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  { 
    path: 'reset-forgot-password', 
    component: ResetForgotPasswordComponent,

  },
  { 
    path: 'test-page', 
    component: TestComponent
  },
  
  { 
    path: 'admin', 
    data:{
      reuse:true
    },
    component:AdminComponent, 
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    // canActivate:[AuthGuard]
  },
  { path: '**', component: LoginComponent }, 
];

@NgModule({
  declarations: [TestComponent, ResetForgotPasswordComponent],
  imports: [
    NgbModule,
    Select2Module,
    MatCommonModule,
    RouterModule.forRoot(routes), 
    FormsModule, 
    ReactiveFormsModule,
    MatButtonModule, 
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    CommonModule,
    MatSelectModule,
    MatTooltipModule,
    CommonModule,
    MatSnackBarModule,
    // ChangePasswordRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    
  ],
  exports: [RouterModule,
    MatButtonModule, 
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    Select2Module,
    MatTooltipModule,
    NgbModule,
    MatSnackBarModule
  ],
 
  schemas: [ CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA ]

})


export class AppRoutingModule { }
