 <!-- header -->
 <header class="container-breakpoint ">
    <nav class="navbar navbar-fixed navbar-expand-lg bg-white sticky-top">
        <div class="container-fluid px-5">
            <a class="navbar-brand" routerLink="/admin/dashboard" *ngIf="isAdmin"><img src="../../../../assets/images/Logo.svg" alt=""></a>
            <a class="navbar-brand" routerLink="/admin/user-dashboard" *ngIf="!isAdmin"><img src="../../../../assets/images/Logo.svg" alt=""></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarTogglerDemo02">
               
                    <ul class="navbar-nav m-auto mb-2 mb-lg-0 flex justify-around" *ngIf="isAdmin">
                        <li [ngClass]="isActive('/admin/dashboard')" class="nav-item px-3" >
                            <a class="nav-link" routerLink="/admin/dashboard"  routerLinkActive="active">Dashboard</a>
                        </li>
                        <li [ngClass]="isActive('/admin/users')" class="nav-item px-3">
                            <a class="nav-link" routerLink="/admin/users"  routerLinkActive="active">Manage Students</a>
                        </li>
                        <li [ngClass]="isActive('/admin/source')" class="nav-item px-3">
                            <a class="nav-link" routerLink="/admin/source"  routerLinkActive="active">Manage Info Sources</a>
                        </li>
                        <li [ngClass]="isActive('/admin/category')" class="nav-item px-3">
                            <a class="nav-link" routerLink="/admin/category"  routerLinkActive="active">Manage Category</a>
                        </li>
                        <li [ngClass]="isActive('/admin/feedback')" class="nav-item px-3">
                            <a class="nav-link" routerLink="/admin/feedback"  routerLinkActive="active">Feedbacks</a>
                        </li>
                    </ul>
                
            
                    <ul class="navbar-nav m-auto mb-2 mb-lg-0 flex justify-around"  *ngIf="!isAdmin">
                        <li [ngClass]="isActive('/admin/user-dashboard')" class="nav-item px-3" >
                            <a class="nav-link" routerLink="/admin/user-dashboard"  routerLinkActive="active">Dashboard</a>
                        </li>
                        <li [ngClass]="isActive('/admin/search-history')" class="nav-item px-3">
                            <a class="nav-link" routerLink="/admin/search-history"  routerLinkActive="active">Search History</a>
                        </li>
                        <li [ngClass]="isActive('/admin/feedback')" class="nav-item px-3">
                            <a class="nav-link" routerLink="/admin/feedback"  routerLinkActive="active">Feedbacks</a>
                        </li>
                    </ul>
               

                <div class="cursor-pointer d-flex" id="user-account-setting" >
                    <!--div class="me-4">
                        <p class="p-0 m-0 d-flex justify-content-end text-last-login">Last Logged In</p>
                        <p class="text-last-login-time m-0">05/22/2023 08:00pm</p>
                    </div-->
                    <!-- <a routerLink="/admin/edit-profile" > -->
                    <span class="profile__icon p-2 badge rounded-pill">
                        <img src="../../../../assets/images/42 13.png" alt="icon" class="nav-icon">
                    </span>
                    <span class="ps-2">
                        <img src="../../../../assets/images/Vector.svg" alt="" srcset="">
                    </span>
                    <!-- </a> -->
                </div>
            </div>
        </div>
    </nav>


</header>

 <!-- Admin pop up -->
 <div class="admin-profile-pop-up ">
    <!-- <img class="admin-profile-pop-up-child cursor-pointer" alt="profile picture" src="../../../../assets/42 13.png"> -->
    <h3 class="john-smith1">{{headData.name}}</h3>
    <div class="johnsmithcom1">{{headData.email}}</div>
    <a routerLink="/admin/edit-profile">
        <div class="edit-profile-wrapper" id="frameContainer">
            <h3 class="edit-profile">Edit Profile</h3>
        </div>
    </a>
    <div class="ellipse-parent">
        <div class="group-child">
        </div>
        <img class="icon" alt="" src="../../../../assets/images/42 13.png">
    </div>
    <div class="row mb-4">

        <a routerLink="/admin/edit-password">
            <h3 class="change-password">Change Password</h3>
        </a>
        <!-- <a href="" data-bs-toggle="modal" data-bs-target="#exampleModal">
            <h3 class="contact-support">Contact Support</h3>
        </a> -->

    </div>

    <div class="user-profile-pop-up-item">
    </div>
    <a (click)="clickLogout()">
        <div class="log-out-parent-user" id="groupContainer1">
            <h3 class="log-out">Log out</h3>
            <div class="group-item">
            </div>
            <img class="log-in-icon" alt="" src="../../../../assets/images/Log in.svg">
        </div>
    </a>
</div>
<!-- Admin pop up end -->
<!-- header end -->