
<div class="body-wrapper">
    <!--  section -->
    <div class='login-section common-section'>
        <div class="container py-3">
            <div class="row">
                <!-- icon and title -->
                <div class="row py-4 m-auto">
                    
                    <div class="col-lg-5 col-md-9 col-sm-12 m-auto py-4">
                        <div class="m-auto rounded-20px">
                            <img src="../../../../assets/images/substract.svg" alt="icon">
                        </div>
                    </div>
                    
                    
                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                        <h2 class="login__title text-center">
                            Welcome, let's get started!
                        </h2>
                    </div>
                </div>
                <!-- form column -->
                <div class="container">
                   <h1 class="text-center">TEST PAGE</h1>
                   <select2 [data]="data"></select2>

                   <!-- <form [formGroup]="fg"> -->
                    <!-- <select2 
                    [overlay]="overlay" 
                    [data]="data9" 
                    multiple="true" 
                    id="selec2-10" 
                    formControlName="state"> </select2> -->
                    <!-- <button (click)="print()">Log Selected</button>
                    <button (click)="resetForm()">Reset Form</button> -->
                <!-- </form> -->
                </div>
                <div class="col-lg-5 col-md-9 col-sm-12 m-auto">

                </div>
            </div>
        </div>
    </div>
</div>
