import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfirmedValidator, CustomValidators } from '../../_helpers/confirm-password.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../_models/UserData';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-reset-forgot-password',
  templateUrl: './reset-forgot-password.component.html',
  styleUrls: ['./reset-forgot-password.component.css']
})
export class ResetForgotPasswordComponent implements OnInit {
  submitted: boolean = false;
  changePass: any = {};
  passwordMatchError:boolean = false;

  form: FormGroup = new FormGroup({});

  uid:any ='';
  token:any = '';

  alertColor:string = '';
  messageSubmit: string = '';
  showMessage: boolean = false;


  rtoken: string;


  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService) {

    this.form = fb.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    }, { 
      validator: ConfirmedValidator('password', 'confirm_password')
    });

    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
      this.rtoken = params['rtoken'];
    });

  }

  ngOnInit() {
    this.getParams();
      console.log('ngOnInit reset forgot')
     
   
   
  }


  getParams(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uid = urlParams.get('uid')
    this.uid =uid
    const rtoken = urlParams.get('rtoken')
    this.token = rtoken;

    console.log('this.token',this.token)
  }


  checkPassword(){
    let val:boolean = false;
    if(this.changePass.password === this.changePass.confirm_password ){
      val = true;
    }
    return val;
  }



 

  get f(){
    return this.form.controls;
  }

  submit(){
    this.passwordMatchError = this.checkPassword();
 
    if(this.passwordMatchError){

      let data = {
        uid: this.uid,
        token: this.token ,
        new_password1: this.changePass.password,
        new_password2: this.changePass.confirm_password 
      }
 
      this.api.postResetForgotPass(data).subscribe((data:any)=>{
        this.showMessage =true;
        this.alertColor ='success';
        this.messageSubmit= 'Success Reset Password';
        this.form.reset();
        
      },(err)=>{
        this.showMessage =true;
        this.alertColor ='danger';
        this.messageSubmit = 'There is a problem with saving your data. <br/> Please check your inputs and try again after some time.. <br/>';

        for (const key in err.error) {
          this.messageSubmit += '\n\n'+"<small>"+`${err.error[key]} </small>`;
        }

      })

    }

  }

  closeSpan() {

    this.showMessage = false;
  }
}
