<!-- <app-header></app-header> -->
<div class="body-wrapper">
    <!-- login section -->
    <div class='login-section common-section'>
        <div class="container py-3">
            <div class="row">
                <!-- icon and title -->
                <div class="row py-4 m-auto">
                    <div class="alert success text-center" *ngIf="showVerify">
                        <span class="closebtn" (click)="closeSpan()" onclick="this.parentElement.style.display='none';">×</span>  
                        <strong>Verified!</strong> Your Email has been Verified
                    </div>
                    <div class="col-lg-5 col-md-9 col-sm-12 m-auto py-4">
                        <div class="m-auto rounded-20px">
                            <img src="../../../../assets/images/substract.svg" alt="icon">
                        </div>
                    </div>
                    
                    
                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                        <h2 class="login__title text-center">
                            Welcome, let's get started!
                        </h2>
                    </div>
                </div>
                <!-- form column -->
                <div class="container">
                    <div class="row m-auto">
                        <div class="form-wrapper col-lg-5 col-md-9 col-sm-12 m-auto px-3 py-4 mb-4">
                            <form class="container row d-flex row-gap-4 p-2 mt-3 m-auto" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
                               
                                <div class="input-group col-lg-4 col-md-6 col-sm-12 flex-fill">
                                    <label for="Email" class="form-label fw-bold fs-5 border-0 ">Email</label>
                                    <div class="input-group input-group input__forms">
                                        <span class="input-group-text border-0 " id="basic-addon3">
                                            <i class="bi bi-person-fill fs-4 input__forms__icons"></i></span>
                                            
                                        <input 
                                            type="email" 
                                            class="form-control input border-start " 
                                            id="Email"
                                            #email="ngModel"
                                            name="email"
    	                                    [class.is-invalid]="email.invalid && email.touched"
                                           
                                            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            aria-describedby="basic-addon3 basic-addon4"
                                            [(ngModel)]="userModel.email" 
                                            autocomplete="on"
                                            placeholder="john@smith.com" required>
                                             <!-- pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$" -->
                                    </div>
                                    <div *ngIf="submitted && (email.errors && (email.invalid || email.touched))">
                                        <small class="text-danger" *ngIf="email.errors['required']">Email is required</small>
                                        <small class="text-danger" *ngIf="email.errors['pattern']">Please provide a valid email address</small>
                                    </div>
                                </div>
                                <div class="input-group col-4">
                                    <label for="Password" class="form-label fw-bold fs-5">Password</label>
                                    <div class="input-group input__forms">
                                        <span class="input-group-text border-0" id="Password">
                                            <img src="../../../../assets/images/lock.svg" alt="">
                                        </span>
                                        <input 
                                            type="password" 
                                            class="form-control input border-start "
                                            [class.is-invalid]="password.invalid && password.touched"
                                            id="passwordInput" 
                                            #password="ngModel"
                                            name="password"
                                            aria-describedby="basic-addon3 basic-addon4"
                                            [(ngModel)]="userModel.password" 
                                            placeholder="*************" required>
                                        <span class="input-group-text border-0" id="togglePassword" (click)="togglePassword()">
                                            <i class="bi bi-eye-fill fs-5 eye__icon"></i>
                                        </span>
                                    </div>
                                    <div *ngIf="submitted && (password.errors && (password.invalid || password.touched))">
                                        <small class="text-danger" *ngIf="password.errors['required']">Password is required</small>
                                    </div>
                                </div>

                                <div class="alert alert-{{alertColor}} alert-dismissible fade show"  (click)="closeSpan()"  role="alert" *ngIf="showMessage">
                                    <div class="alertMessage" [innerHTML]=messageSubmit></div>
                                </div>

                                <!-- <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="showSuccessMessage">
                                    <span class="closebtn" (click)="closeSpan()"  aria-hidden="true">&times;</span>
                                        {{messageSubmitSuccess}}
                                </div>
                                <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showErrorMessage">
                                    <span class="closebtn" (click)="closeSpan()"  aria-hidden="true">&times;</span>
                                        {{messageSubmitError}}
                                  </div> -->
                                <div class="input-group col-4 input__forms mb-4">
                                    <button type="submit"
                                        class="btn btn-primary col-12 fs-5 fw-bold rounded-5 lh-base btn-login" >
                                        Login</button>
                                </div>
                            </form>

                            
                            <div class="text-center mb-4">
                                    <a routerLink="/forgot-password" class="text-black fw-semibold fs-6 forgot ">forgot your
                                        Password? </a>
                                    
                            </div>
                            <div class="text-center">
                                    <p class="text-capitalize text-secondary">No account Yet? <span><a
                                        routerLink="/signup" class="text-blue color-blue">Create
                                        Account</a></span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-9 col-sm-12 m-auto">

                </div>
            </div>
        </div>
    </div>
</div>
