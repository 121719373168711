<!-- <p>reset-forgot-password works!</p> -->
<div class="admin__body-wrapper ">
    <!-- login section -->
    <div class='login-section common-section'>

        <div class="container py-3 ">
            <div class="row">
                <!-- icon and title -->
                <div class="row py-4 m-auto">
                    <div class="col-lg-5 col-md-9 col-sm-12 m-auto py-4">
                        <div class="m-auto rounded-20px">
                            <img src="../../../../assets/images/substract.svg" alt="icon">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                        <h2 class="login__title text-center">
                            Welcome, let's get started!
                        </h2>
                    </div>
                </div>

                <!-- form column -->
                <div class="container">
                    <div class="row m-auto">
                        <div
                            class="form-wrapper col-lg-5 col-md-9 col-sm-12 m-auto p-1 pb-2 px-xl-5 px-lg-4 px-md-4 mb-4">
                           
                            <div 
                                class="alert alert-{{alertColor}} alert-dismissible fade show"
                                (click)="closeSpan()"  
                                role="alert" 
                                *ngIf="showMessage"
                                >
                                <div class="alertMessage" [innerHTML]=messageSubmit></div>
                            </div>
                            <br/>
                            <form 
                                class="container p-lg-3 p-sm-3 mt-4 m-auto" 
                                [formGroup]="form" 
                                (ngSubmit)="submit()">
                                <div class="row d-flex row-gap-4 pt-4 m-auto">
                                  
                                    <div class="input-group col-4">
                                        <label for="Password" class="form-label fw-bold fs-5">Password 22</label>
                                        <div class="input-group input__forms">
                                            <span class="input-group-text border-0" id="Password">
                                                <img src="../../../../assets/images/lock.svg" alt="">
                                            </span>
                                            
                                                
                                            <input
                                                formControlName="password"
                                                type="password" 
                                                class="form-control input border-start "
                                                [class.is-invalid]="f.password.invalid && f.password.touched"
                                                id="passwordInput" 
                                                name="password"
                                                aria-describedby="basic-addon3 basic-addon4"
                                                 autocomplete=""
                                                placeholder="*************"
                                                [(ngModel)]="changePass.password"
                                                 required>
                                               
                                            <span class="input-group-text border-0" id="togglePassword">
                                                <i class="bi bi-eye-fill fs-5 eye__icon"></i>
                                            </span>
                                        </div>
                                        
                                        <div *ngIf="f.password.touched && f.password.invalid">
                                            <small class="text-danger" *ngIf="f.password.errors.required">Password is required</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 flex-fill">
                                        <div class="input-group col-6">
                                            <label for="Confirm Password" class="form-label fw-bold fs-5">Confirm Password</label>
                                            <div class="input-group input__forms">
                                                <span class="input-group-text border-0 " id="basic-addon3">
                                                    <img src="../../../../assets/images/lock.svg" alt=""></span>
                                                  
                                                    <input 
                                                    formControlName="confirm_password"
                                                    type="password" 
                                                    class="form-control input border-start "
                                                    [class.is-invalid]="f.confirm_password.invalid && f.confirm_password.touched"
                                                    id="CpasswordInput1" 
                                                    autocomplete=""
                                                    name="confirm_password"
                                                    aria-describedby="basic-addon3 basic-addon4"
                                                    [(ngModel)]="changePass.confirm_password" 
                                                    placeholder="*************"
                                                     required>
                                                    
                                                <span class="input-group-text input" id="toggleCPassword1">
                                                    <i class="cpass bi bi-eye-fill fs-5 eye__icon"></i>
                                                </span>
                                            </div>

                                            <div *ngIf="f.confirm_password.touched && f.confirm_password.invalid">
                                                <small class="text-danger" *ngIf="f.confirm_password.errors.required">Password is required</small>
                                                <small class="text-danger" *ngIf="f.confirm_password.errors.confirmedValidator">Password does not match</small>
        
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group col-4 input__forms mb-4">
                                        <button type="submit"
                                            class="btn btn-primary col-12 fs-5 fw-bold rounded-5 lh-base btn-login text-uppercase" 
                                            [disabled]="!form.valid">
                                            reset password</button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>