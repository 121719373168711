import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Select2Data } from 'ng-select2-component';


export const data1: Select2Data = [
  {
      label: 'Alaskan/Hawaiian Time Zone',
      options: [
          { value: 'AK', label: 'Alaska' },
          { value: 'HI', label: 'Hawaii', disabled: true },
      ],
  },
  {
      label: 'Pacific Time Zone',
      options: [
          { value: 'CA', label: 'California' },
          { value: 'NV', label: 'Nevada' },
          { value: 'OR', label: 'Oregon' },
          { value: 'WA', label: 'Washington' },
      ],
  },
  {
      label: 'Mountain Time Zone',
      options: [
          { value: 'AZ', label: 'Arizona' },
          { value: 'CO', label: 'Colorado' },
          { value: 'ID', label: 'Idaho' },
          { value: 'MT', label: 'Montana' },
          { value: 'NE', label: 'Nebraska' },
          { value: 'NM', label: 'New Mexico' },
          { value: 'ND', label: 'North Dakota' },
          { value: 'UT', label: 'Utah' },
          { value: 'WY', label: 'Wyoming' },
      ],
  },
  {
      label: 'Central Time Zone',
      options: [
          { value: 'AL', label: 'Alabama' },
          { value: 'AR', label: 'Arkansas' },
          { value: 'IL', label: 'Illinois' },
          { value: 'IA', label: 'Iowa' },
          { value: 'KS', label: 'Kansas' },
          { value: 'KY', label: 'Kentucky' },
          { value: 'LA', label: 'Louisiana' },
          { value: 'MN', label: 'Minnesota' },
          { value: 'MS', label: 'Mississippi' },
          { value: 'MO', label: 'Missouri' },
          { value: 'OK', label: 'Oklahoma' },
          { value: 'SD', label: 'South Dakota' },
          { value: 'TX', label: 'Texas' },
          { value: 'TN', label: 'Tennessee' },
          { value: 'WI', label: 'Wisconsin' },
      ],
  },
  {
      label: 'Eastern Time Zone',
      options: [
          { value: 'CT', label: 'Connecticut' },
          { value: 'DE', label: 'Delaware' },
          { value: 'FL', label: 'Florida' },
          { value: 'GA', label: 'Georgia' },
          { value: 'IN', label: 'Indiana' },
          { value: 'ME', label: 'Maine' },
          { value: 'MD', label: 'Maryland' },
          { value: 'MA', label: 'Massachusetts' },
          { value: 'MI', label: 'Michigan' },
          { value: 'NH', label: 'New Hampshire' },
          { value: 'NJ', label: 'New Jersey' },
          { value: 'NY', label: 'New York' },
          { value: 'NC', label: 'North Carolina' },
          { value: 'OH', label: 'Ohio' },
          { value: 'PA', label: 'Pennsylvania' },
          { value: 'RI', label: 'Rhode Island' },
          { value: 'SC', label: 'South Carolina' },
          { value: 'VT', label: 'Vermont' },
          { value: 'VA', label: 'Virginia' },
          { value: 'WV', label: 'West Virginia' },
      ],
  },
];
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit{

  

  data: Select2Data  = [
    {
      "label": "Red",
      "data": {
        "name": "(Red)"
      },
      "options": [
        {
          "value": "hibiscus",
          "label": "Hibiscus",
          "data": {
            "color": "red",
            "name": "Hibiscus"
          },
          "templateId": "template1",
          "id": "option-hibiscus"
        },
        {
          "value": "marigold",
          "label": "Marigold",
          "data": {
            "color": "red",
            "name": "Marigold"
          },
          "templateId": "template2",
          "id": "option-marigold"
        }
      ]
    },
    {
      "label": "Yellow",
      "data": {
        "name": "(Yellow)"
      },
      "options": [
        {
          "value": "sunflower",
          "label": "Sunflower",
          "data": {
            "color": "yellow",
            "name": "Sunflower"
          },
          "templateId": "template3",
          "id": "option-sunflower"
        }
      ]
    },
    {
      "label": "White",
      "data": {
        "name": "(White)"
      },
      "options": [
        {
          "value": "heliotrope",
          "label": "Heliotrope",
          "data": {
            "color": "white",
            "name": "Heliotrope"
          },
          "templateId": "template1",
          "id": "option-heliotrope"
        },
        {
          "value": "lily",
          "label": "Lily",
          "data": {
            "color": "white",
            "name": "Lily"
          },
          "templateId": "template2",
          "id": "option-lily"
        },
        {
          "value": "petunia",
          "label": "Petunia",
          "data": {
            "color": "white",
            "name": "Petunia"
          },
          "templateId": "template3",
          "id": "option-petunia"
        }
      ]
    }
  ]

  fg: UntypedFormGroup = new UntypedFormGroup({
    state: new UntypedFormControl(),
});
  

  data1 = data1;
  overlay = false;

  data9: Select2Data = JSON.parse(JSON.stringify(data1));

  ngOnInit(): void {
   console.log('ngOnInit test')
  }

  closeSpan(){
   
  }

  print() {
   
  }

  resetForm() {
    this.fg.reset(this.formData());
}


formData() {
  return {
      state: ['CA', 'NV'],
  };
}

}
