import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule,  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ReactiveFormsModule , FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { MatTableModule } from '@ngmodule/material-carousel';
import { MatTableModule, MatTableDataSource} from '@angular/material/table'; 
import { MatButtonModule,} from '@angular/material/button';
import { MatInputModule,} from '@angular/material/input'; 
import { MatPaginatorModule,} from '@angular/material/paginator'; 
import { MatSortModule,} from '@angular/material/sort'; 
import { NgSelectModule } from "@ng-select/ng-select"; 
import {MatStepperModule} from '@angular/material/stepper';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatFormFieldModule} from '@angular/material/form-field';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';

import { MatSelectModule } from '@angular/material/select';
import { Select2Module } from 'ng-select2-component';

import { TagifyModule } from 'ngx-tagify';
import { NgxPaginationModule } from 'ngx-pagination';

import {MatTooltipModule} from '@angular/material/tooltip';

import { HashLocationStrategy, LocationStrategy  } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgxPaginationModule,
    Select2Module,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    MatSelectModule,
    MatSortModule,
    MatButtonModule, 
    MatInputModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    BrowserAnimationsModule,
    CommonModule,
    AngularFireModule,
    AngularFireModule.initializeApp(environment.firebase),
    TagifyModule.forRoot(),
    
  ],
  exports:[Select2Module],
  providers: [
    // { provide: FIREBASE_OPTIONS, useValue: environment.firebase},
     { provide : LocationStrategy , useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ] 
  
})
export class AppModule { }
