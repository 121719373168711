import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit{

  headData:any ={};
  isAdmin:boolean = false;
  constructor(
    private router:Router,
    public authService:AuthService, 
    public api:ApiService){

  }

  ngOnInit(): void {
    let key = localStorage.getItem('key');

    environment.firebase.apiKeyStorage = 'Token '+key;
    this.scriptsMain();
    this.getUserDetails();

  }
 
  isActive(url:string){
    const routerUrl = this.router.url;
    let act = '';
    if(url === routerUrl){
      act = 'active'
    }
    return act;
  }

  getUserDetails(){
    
    this.api.getUserDetails().subscribe((data)=>{
      
      this.isAdmin = false
      if(data.groups[0] ===1){
       this.isAdmin = true
      }
      this.headData = data;

    },(err)=>{
      console.log('err',err);
      localStorage.clear();
      this.router.navigate(['/login']);
      let dataKey = environment.firebase.apiKeyStorage;
      this.authService.SignOut(dataKey);
    })
  }

  

  scriptsMain(){
    let frameContainer:any = document.getElementById("user-account-setting");
    let adminProfilePopUp:any = document.querySelector(".admin-profile-pop-up");
    if (frameContainer && adminProfilePopUp) {
        frameContainer.addEventListener("click", function (e: { stopPropagation: () => void; }) {
            // Toggle the pop-up
            adminProfilePopUp.classList.toggle("show");

            // Prevent the click event from propagating to the document click listener
            e.stopPropagation();
        });

        // Add a click event listener to the document to hide the pop-up when clicking outside of it
        document.addEventListener("click", function (e) {
            if (!adminProfilePopUp.contains(e.target) && !frameContainer.contains(e.target)) {
                adminProfilePopUp.classList.remove("show");
            }
        });
    }
  }

  clickLogout(){
    let dataKey = environment.firebase.apiKeyStorage;
    this.authService.SignOut(dataKey);
  }

}
