import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  headers = new HttpHeaders();
  headersAuth = new HttpHeaders();
  public currentUserSubject = new BehaviorSubject<any>(null); // initializing with no user object since logged out

  // .set('Content-type', 'application/json')
  // .set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');

  constructor(private http: HttpClient) {
    // this.currentUserSubject.next(null)
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('userDetails')))
    this.headers = this.headers.append(
      environment.firebase.SITE_KEY_HEADER_PARAMETER_NAME,
      environment.firebase.SITE_KEY_VALUE
    );
    this.headersAuth = this.headersAuth
      .set(
        environment.firebase.SITE_KEY_HEADER_PARAMETER_NAME,
        environment.firebase.SITE_KEY_VALUE
      )
      .set('Authorization', environment.firebase.apiKeyStorage);

    this.headersAuth = this.headersAuth
      .set(
        environment.firebase.SITE_KEY_HEADER_PARAMETER_NAME,
        environment.firebase.SITE_KEY_VALUE
      )
      .set('Authorization', environment.firebase.apiKeyStorage);
  }

  public get currentUserValue(){
    return this.currentUserSubject.value;
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  //TOdos
  postSignUp(data: any): Observable<any> {
    const url = environment.firebase.rootUrl + `/rest-auth/register/`;
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  postLogin(data: any): Observable<any> {
    const url = environment.firebase.rootUrl + `/rest-auth/login/`;
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  postForgotPass(data: any): Observable<any> {
    const url = environment.firebase.rootUrl + `/rest-auth/password/reset/`;
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  postLogout(data: any): Observable<any> {
    const url = environment.firebase.rootUrl + `/rest-auth/logout/`;
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  getUserDetails(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', environment.firebase.apiKeyStorage)
      .set(
        environment.firebase.SITE_KEY_HEADER_PARAMETER_NAME,
        environment.firebase.SITE_KEY_VALUE
      );

    const url = environment.firebase.rootUrl + `/rest-auth/user/`;
    return this.http.get<any>(url, { headers: headers });
  }

  getUserDetailsLogin(key: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Token ' + key)
      .set(
        environment.firebase.SITE_KEY_HEADER_PARAMETER_NAME,
        environment.firebase.SITE_KEY_VALUE
      );

    const url = environment.firebase.rootUrl + `/rest-auth/user/`;
    return this.http.get<any>(url, { headers: headers });
  }

  postResetForgotPass(data: any) {
    const url =
      environment.firebase.rootUrl + `/rest-auth/password/reset/confirm/`;
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  postPassChange(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', environment.firebase.apiKeyStorage)
      .set(
        environment.firebase.SITE_KEY_HEADER_PARAMETER_NAME,
        environment.firebase.SITE_KEY_VALUE
      );
    const url = environment.firebase.rootUrl + `/rest-auth/password/change/`;

    return this.http.post<any>(url, data, { headers: headers });
  }
}
